import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useState } from 'react';
import { Fragment } from 'react';
import { logEvent } from "firebase/analytics";
import { analytics } from '../firebase';  // Adjust this path to match your project structure

function ImageGrid({ images, onExampleSelect }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(null);

  function openModal(image) {
    setActiveImage(image);
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function handleTryThisExample() {
    onExampleSelect(activeImage.title);
    closeModal();
    logEvent(analytics, 'try_example_button_click', { example_name: activeImage.title });
  }

  return (
    <div className="mt-24 grid grid-cols-5 grid-rows-2 gap-1">
      {images.map((image, index) => (
        <div 
          key={index} 
          className={`relative group cursor-pointer ${index === 0 ? 'col-span-2 row-span-2' : 'col-span-1 row-span-1'}`} 
          onClick={() => openModal(image)}
        >
          <img src={image.src} alt={image.title} className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-white bg-opacity-0 group-hover:bg-opacity-80 flex flex-col justify-between items-center transition-opacity duration-200 p-3">
            <p className="text-opacity-0 group-hover:text-opacity-100 text-gray-800 text-sm">{image.title}</p>
            <p className="text-opacity-0 group-hover:text-opacity-100 text-gray-600 text-xs">Click to try</p>
          </div>
        </div>
      ))}
      {activeImage && (
        <Transition appear show={isModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="min-h-screen px-4 text-center flex items-center justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-80 transition-opacity" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block bg-white w-120 rounded-lg text-left shadow-xl">
                  <img src={activeImage.src} alt={activeImage.title} className="w-full h-auto rounded-t-lg" />
                  <div className="m-10 mt-6">
                    <Dialog.Title
                      as="h2"
                      className="mt-4 ml-10 mr-10 text-sm text-gray-900 text-center"
                    >
                      {activeImage.title}
                    </Dialog.Title>
                    <div className="mt-6 mb-4">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-3.5 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none"
                        onClick={handleTryThisExample}
                      >
                        Try this example
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
    </div>
  );
}

export default ImageGrid;
