import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import axios from 'axios';
import BeatLoader from "react-spinners/BeatLoader";
import { logEvent } from "firebase/analytics";
import { analytics } from '../firebase';  // Adjust this path to match your project structure

function FeedbackForm({ uid, did }) {
  const [isOpen, setIsOpen] = useState(false);
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state

  const openModal = (rating) => {
    setRating(rating);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set loading to true before API request
    try {
        await axios.post('/api/feedback', { uid, did, rating, feedback });
        setFeedback('')
        closeModal();
    } catch (error) {
        console.error(error);
        setFeedback('')
        closeModal();
    } finally {
      setIsLoading(false); // Set loading to false after API request
      logEvent(analytics, 'submit_feedback_button_click', { rating: rating, feedback_length: feedback.length });
    }
  };

  return (
    <div className="FeedbackForm m-10">
      <button 
        className="bg-white shadow-md rounded-md p-3 mx-2 hover:bg-gray-100 transition"
        onClick={() => openModal('thumbs up')}
      >
        👍
      </button>
      <button 
        className="bg-white shadow-md rounded-md p-3 mx-2 hover:bg-gray-100 transition"
        onClick={() => openModal('thumbs down')}
      >
        👎
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

            <div className="inline-block w-full max-w-5xl p-6 my-10 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
              <div className="flex justify-between items-center pb-6">
                <div className="flex items-center">
                  <span className="mr-2">{rating === 'thumbs up' ? '👍' : '👎'}</span>
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Provide additional feedback
                  </Dialog.Title>
                </div>
                <button className="text-gray-400 hover:text-gray-600" onClick={closeModal}>X</button>
              </div>

              <hr className="mb-6" />

              <div className="mt-2">
                <textarea
                  value={feedback}
                  onChange={(event) => setFeedback(event.target.value)}
                  className="w-full border-gray-300 rounded-md p-2 mt-2 text-md"
                  rows={3}
                  placeholder={rating === 'thumbs up' ? 'What do you like about the generated dashboard?' : 'What was the issue with the generated dashboard? How could it be improved?'}
                />
              </div>

              <div className="mt-4 text-right">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm text-gray-600 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={handleSubmit}
                  disabled={isLoading} // disable button during loading
                >
                  {isLoading ? <BeatLoader color="#4A5568" size={8} /> : 'Submit feedback'}
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default FeedbackForm;
