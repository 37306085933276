import React from 'react';

function Scorecard({scorecard}) {
  // Split the scorecard string into title and value
  const [title, value] = scorecard.split(' - ');

  return (
    <div className="bg-white shadow-md rounded-lg p-5 text-center">
      <h4 className="text-lg text-gray-700 font-bold mb-2">{title}</h4>
      <p className="text-xl text-gray-700">{value}</p>
    </div>
  );
}

function Scorecards({scorecards}) {
  return (
    <div className="grid grid-cols-4 gap-4 mb-4 mt-5">
      {scorecards.map((scorecard, index) => (
        <Scorecard key={index} scorecard={scorecard} />
      ))}
    </div>
  );
}

export default Scorecards;
