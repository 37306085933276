import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FiMoreVertical, FiDownload } from 'react-icons/fi';
import { saveAs } from 'file-saver';
import * as d3 from 'd3';

function SummaryTable({ title, content }) {
  const data = parseMarkdownTable(content);

  // Function to handle csv download
  const downloadCSV = () => {
    let blob = new Blob([d3.csvFormatRows(data)], {type: "text/csv;charset=utf-8"});
    saveAs(blob, `${title}_DASH-E_Export_${new Date().toISOString()}.csv`);
  };  

  return (
    <div className="mt-4 relative p-4 bg-white rounded-md shadow-lg">
      <div className="flex justify-between items-center pb-2">
        <h3 className="text-s font-semibold text-gray-700">{title}</h3>
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <Menu.Button className="inline-flex items-center justify-center w-6 h-6 bg-gray-600 rounded-full hover:bg-gray-500 focus:outline-none">
                <FiMoreVertical className="text-white"/>
              </Menu.Button>
              <Transition
                show={open}
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="py-0.5 px-0.5">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-violet-500 text-white' : 'text-gray-700'
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          onClick={downloadCSV}
                        >
                          <FiDownload className="ml-1 mr-3 hover:text-violet-100"/>
                          Download CSV
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
      <div className="mt-4 overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {data[0].map((header, i) => (
                <th key={i} className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.slice(1).map((row, i) => (
              <tr className=" hover:bg-gray-50" key={i}>
                {row.map((cell, j) => (
                  <td key={j} className="text-sm px-4 py-2 whitespace-nowrap text-gray-800">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function parseMarkdownTable(markdown) {
  const lines = markdown.split('\n').filter(line => line.trim() !== '' && !line.startsWith('|---'));
  return lines.map(line => line.split('|').slice(1, -1).map(cell => cell.trim()));
}

export default SummaryTable;
