import React, { useEffect, useState, Fragment } from "react";
import { initializeApp, getApps, getApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  getDoc,
} from "firebase/firestore";
import { useAuth0 } from "@auth0/auth0-react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import BeatLoader from "react-spinners/BeatLoader";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}
const db = getFirestore(app);

function Admin() {
  const [promptData, setPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [newMessage, setNewMessage] = useState({ role: "", content: "" });
  const { user, isAuthenticated } = useAuth0();
  const adminIds = process.env.REACT_APP_ADMIN_IDS.split(",");
  const [selectedPrompt2, setSelectedPrompt2] = useState(null);

  useEffect(() => {
    if (isAuthenticated && adminIds.includes(user.sub)) {
      const fetchData = async () => {
        const promptsCollection = collection(db, "admin");
        const promptsSnapshot = await getDocs(promptsCollection);
        const promptsData = promptsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPrompts(promptsData);
      };
      fetchData();
    }
  }, [isAuthenticated, user]);

  const handleSelectChange = (e) => {
    setSelectedPrompt(
      promptData.find((prompt) => prompt.id === e.target.value)
    );
  };

  const handleTextAreaChange = (e, index) => {
    let updatedMessages = [...selectedPrompt.messages];
    updatedMessages[index].content = e.target.value;
    setSelectedPrompt({ ...selectedPrompt, messages: updatedMessages });
  };

  const handleSaveChanges = async () => {
    const promptRef = doc(db, "admin", selectedPrompt.id);
    await updateDoc(promptRef, { messages: selectedPrompt.messages });
  };

  const handleSaveChanges2 = async () => {
    const promptRef = doc(db, "admin", selectedPrompt2.id);
    await updateDoc(promptRef, { messages: selectedPrompt2.messages });
  };

  const handleAddNewMessage = async () => {
    const promptRef = doc(db, "admin", selectedPrompt.id);
    await updateDoc(promptRef, {
      messages: [...selectedPrompt.messages, newMessage],
    });
    setNewMessage({ role: "", content: "" });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPromptName, setNewPromptName] = useState("");
  const [newPromptMessages, setNewPromptMessages] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const addNewPrompt = async () => {
    let newPromptMessagesParsed;
    try {
      newPromptMessagesParsed = JSON.parse(newPromptMessages);
    } catch (e) {
      alert("Invalid JSON format. Please check your input.");
      return;
    }

    const newPrompt = {
      name: newPromptName,
      messages: newPromptMessagesParsed,
    };

    await addDoc(collection(db, "admin"), newPrompt);

    // Refresh prompts
    const promptsCollection = collection(db, "admin");
    const promptsSnapshot = await getDocs(promptsCollection);
    const promptsData = promptsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setPrompts(promptsData);

    closeModal();
  };

  const handleSelectChange2 = async (e) => {
    const docSnapshot = await getDoc(doc(db, "admin", e.target.value));
    setSelectedPrompt2({ id: e.target.value, ...docSnapshot.data() });
  };

  const handleTextAreaChange2 = (e, index) => {
    setSelectedPrompt2((prevPrompt) => {
      const updatedMessages = [...prevPrompt.messages];
      updatedMessages[index].content = e.target.value;
      return { ...prevPrompt, messages: updatedMessages };
    });
  };

  const handleSaveAs = async () => {
    const newPromptName = prompt(promptData.id + " - copy");
    const newPrompt = {
      name: newPromptName,
      messages: selectedPrompt.messages,
    };
    await addDoc(collection(db, "admin"), newPrompt);
    // refresh prompts here
  };

  const handleSaveAs2 = async () => {
    const newPromptName = prompt(promptData.id + " - copy");
    const newPrompt = {
      name: newPromptName,
      messages: selectedPrompt2.messages,
    };
    await addDoc(collection(db, "admin"), newPrompt);
    // refresh prompts here
  };

  const [testResponse1, setTestResponse1] = useState(null);
  const [testResponse2, setTestResponse2] = useState(null);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const handleTestPrompt = async (prompt) => {
    setIsLoading1(true); // start loading
    const response = await fetch("/api/test-prompt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(prompt.messages),
    });

    const result = await response.json();
    setTestResponse1(result.content);
    setIsLoading1(false); // end loading
  };

  const handleTestPrompt2 = async (prompt) => {
    setIsLoading2(true); // start loading
    const response = await fetch("/api/test-prompt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(prompt.messages),
    });

    const result = await response.json();
    setTestResponse2(result.content);
    setIsLoading2(false); // end loading
  };

  if (!isAuthenticated || !adminIds.includes(user.sub)) {
    return <div>Access denied.</div>;
  }

  return (
    <div className='w-full flex-auto'>
      <div className='px-16 pt-5 ml-16 mb-4'>
        <h1 className='mt-12 mb-6 text-xl font-bold text-gray-900'>
          Prompt Manager
        </h1>
        <div className='mt-8 flex justify-between space-x-8'>
          <div className='w-1/2 space-y-4'>
            <label className='text-sm font-medium text-gray-700'>
              Prompt selector
            </label>
            <select
              className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
              onChange={handleSelectChange}
            >
              <option>Select a prompt</option>
              {promptData.map((prompt, index) => (
                <option key={index} value={prompt.id}>
                  {prompt.name}
                </option>
              ))}
            </select>
            {selectedPrompt && (
              <div className='mt-8'>
                {selectedPrompt.messages.map((message, index) => (
                  <div key={index} className='space-y-0'>
                    <p className='font-semibold text-gray-800 text-xs mt-2 mb-0.5'>
                      {message.role.toUpperCase()}
                    </p>
                    <textarea
                      value={message.content}
                      onChange={(e) => handleTextAreaChange(e, index)}
                      className='w-full h-24 px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-sm focus:shadow-outline'
                    />
                  </div>
                ))}
                <button
                  onClick={() => handleTestPrompt(selectedPrompt)}
                  className='mt-4 inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-black hover:bg-gray-800'
                >
                  {isLoading1 ? <BeatLoader color="#ffffff" size={8} /> : 'TEST ⚡️'}
                </button>
                <button
                  onClick={handleSaveChanges}
                  className='mt-4 ml-4 inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700'
                >
                  Save changes
                </button>
                <button
                  onClick={handleSaveAs}
                  className='mt-4 ml-4 inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-green-500 hover:bg-green-600'
                >
                  Save as
                </button>
                {testResponse1 && (
                  <div
                    className='mt-6 p-4 border border-gray-300 rounded-md bg-white'
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <h3 className='font-semibold text-gray-800 mb-2'>
                      Test Result:
                    </h3>
                    <p>{testResponse1}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='w-1/2 space-y-4'>
            <label className='text-sm font-medium text-gray-700'>
              Prompt selector
            </label>
            <select
              className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
              onChange={handleSelectChange2}
            >
              <option>Select a prompt</option>
              {promptData.map((prompt, index) => (
                <option key={index} value={prompt.id}>
                  {prompt.name}
                </option>
              ))}
            </select>
            {selectedPrompt2 && (
              <div className='mt-8'>
                {selectedPrompt2.messages.map((message, index) => (
                  <div key={index} className='space-y-0'>
                    <p className='font-semibold text-gray-800 text-xs mt-2 mb-0.5'>
                      {message.role.toUpperCase()}
                    </p>
                    <textarea
                      value={message.content}
                      onChange={(e) => handleTextAreaChange2(e, index)}
                      className='w-full h-24 px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-sm focus:shadow-outline'
                    />
                  </div>
                ))}
                <button
                  onClick={() => handleTestPrompt2(selectedPrompt2)}
                  className='mt-4 inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-black hover:bg-gray-800'
                >
                  {isLoading2 ? <BeatLoader color="#ffffff" size={8} /> : 'TEST ⚡️'}
                </button>
                <button
                  onClick={handleSaveChanges2}
                  className='mt-4 ml-4 inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700'
                >
                  Save changes
                </button>
                <button
                  onClick={handleSaveAs2}
                  className='mt-4 ml-4 inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-green-500 hover:bg-green-600'
                >
                  Save as
                </button>
                {testResponse2 && (
                  <div
                    className='mt-6 p-4 border border-gray-300 rounded-md bg-white'
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <h3 className='font-semibold text-gray-800 mb-2'>
                      Test Result:
                    </h3>
                    <p>{testResponse2}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='mt-8 space-y-4'>
          {/* <h2 className="text-lg font-semibold text-gray-800">
              Add a new message
            </h2>
            <select
              onChange={(e) => setNewMessage({ ...newMessage, role: e.target.value })}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option>Select role</option>
              <option value="system">System</option>
              <option value="user">User</option>
              <option value="assistant">Assistant</option>
            </select>
            <textarea
              value={newMessage.content}
              onChange={(e) =>
                setNewMessage({ ...newMessage, content: e.target.value })
              }
              className="w-full h-24 px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
            />
            <button
              onClick={handleAddNewMessage}
              className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Add new message
            </button> */}
        </div>
        <div className='mt-8 space-y-4'>
          <button
              onClick={openModal}
              className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Add new prompt
            </button>

                <Transition appear show={isModalOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                        >
                            Add new prompt
                        </Dialog.Title>

                        <div className="mt-2">
                            <label>Prompt name:</label>
                            <input
                            type="text"
                            onChange={(e) => setNewPromptName(e.target.value)}
                            className="w-full px-3 py-2 mt-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
                            />
                            <label>Messages (JSON format):</label>
                            <textarea
                            onChange={(e) => setNewPromptMessages(e.target.value)}
                            className="w-full h-64 px-3 py-2 mt-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline"
                            />
                        </div>

                        <div className="mt-4">
                            <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                            onClick={addNewPrompt}
                            >
                            Submit
                            </button>
                        </div>
                        </div>
                    </Transition.Child>
                    </div>
                </Dialog>
                </Transition>
        </div>
      </div>
    </div>
  );
}

export default Admin;
