import React from 'react';

const TermsOfUsePage = () => {
  const lastUpdatedDate = 'May 31, 2023';

  return (
    <div className="m-6">
      <h1 className="text-4xl font-bold mb-6">Terms of Use</h1>
      <p className="text-lg text-gray-700 mb-8">
        By accessing and using Text2BI DASH·E, you agree to the following terms and conditions:
      </p>
      
      <h2 className="text-2xl font-bold mb-4">Age Restrictions</h2>
      <p className="text-lg text-gray-700 mb-4">
        Text2BI DASH·E is intended for users who are at least 13 years old. If you are under the age of 18, you must obtain permission from a parent or guardian before using our services.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">Prohibited Activities</h2>
      <p className="text-lg text-gray-700 mb-4">
        The following activities are prohibited on Text2BI DASH·E:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>Creating multiple accounts for the same person to receive the same free credits multiple times.</li>
        {/* Add more prohibited activities as needed */}
      </ul>
      
      <p className="text-lg text-gray-700 mb-8">
        If you have any questions or concerns about our Terms of Use, please contact us at founders [at] text2bi.com.
      </p>
      
      <p className="text-sm text-gray-500">Last Updated: {lastUpdatedDate}</p>
    </div>
  );
};

export default TermsOfUsePage;
