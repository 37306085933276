import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

const DashboardTitle = ({ title }) => {
  // For the date range picker
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
    setIsOpen(false);
  }

  return (
    <div className="flex justify-between items-center p-5 bg-white shadow-md rounded-lg mb-4">
      <h2 className="text-2xl font-bold text-gray-700">{title}</h2>
      <div className="relative">
        <button 
          className="flex items-center border border-gray-300 shadow-sm rounded-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
          onClick={() => setIsOpen(!isOpen)}
        >
          {format(state[0].startDate, 'MM/dd/yyyy')} - {format(state[0].endDate, 'MM/dd/yyyy')}
          <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-500" />
        </button>
        {isOpen && (
          <div style={{ zIndex: 999 }} className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none p-2">
            <DateRangePicker
              ranges={state}
              onChange={handleSelect}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
              color="#3F51B5"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardTitle;
