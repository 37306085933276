import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, doc } from 'firebase/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import axios from 'axios';  // Make sure axios is imported
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app); // Get the Firebase Auth instance

function History() {
    const [dashboardData, setDashboards] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const { user, getIdTokenClaims } = useAuth0();
  
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);  // Data fetching starts here. So, we set isLoading to true.
    
        // Get the Auth0 access token
        try {
          // console.log('Before the ID token: ');
          // // Get the Auth0 ID token
          // const idTokenClaims = await getIdTokenClaims();
          // const idToken = idTokenClaims.__raw;
          // console.log('The ID token: ', idToken);

          // // Get the custom Firebase token
          // const { data: { firebaseToken } } = await axios.post('http://localhost:3000/api/customToken', {
          //   idToken: idToken, // Pass the ID token instead of the access token
          // })

          // // Sign in to Firebase with the custom token
          // await signInWithCustomToken(auth, firebaseToken);
    
          const userId = user.sub.split('|')[1];
          const dashboardsCollection = collection(doc(db, 'users', userId), 'dashboards');
          const dashboardSnapshot = await getDocs(dashboardsCollection);
          const dashboardsData = dashboardSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
          // Sort dashboards by creationDateTime
          dashboardsData.sort((a, b) => b.creationDateTime.toDate() - a.creationDateTime.toDate());
    
          setDashboards(dashboardsData);
          setIsLoading(false);  // Data fetching ends here. So, we set isLoading to false.
        } catch (error) {
          // console.error('Error getting access token:', error);
          setIsLoading(false);
        }
      }
      
      fetchData();
    }, []);    

    const filteredDashboards = dashboardData.filter(dashboard => 
      dashboard.dashboardTitle.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    return (
      <div className="w-full flex-auto">
        <div className="px-16 pt-5 ml-16 mb-4">
          <h1 className="mt-12 mb-6 text-xl font-bold text-gray-900">History</h1>
          <input
            type="text"
            placeholder="Search DASH-E"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className="w-full mb-2 p-2 border-gray-200 rounded"
          />
          <div className="flex space-x-4 mt-8">
            {/* <h2 className="text-sm font-medium text-gray-700">All Generations</h2> */}
            {/* <h2 className="text-sm font-medium text-gray-700">Favorites</h2> */}
          </div>
          <div className="mt-8">
          {isLoading ? (
            <Loading />
          ) : (
            <table className="table-auto border-collapse w-full">
              <thead>
                <tr className="rounded-lg text-sm font-medium text-gray-700 text-left" style={{fontSize: '0.9674rem'}}>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Created on</th>
                  <th className="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="text-sm font-normal text-gray-700">
                {filteredDashboards.map((dashboard, index) => (
                  <tr key={index} className="hover:bg-gray-100 border-b border-gray-200 py-10">
                    <td className="px-4 py-4">{dashboard.dashboardTitle}</td>
                    <td className="px-4 py-4">{dashboard.creationDateTime.toDate().toLocaleString()}</td>
                    <td className="px-4 py-4">
                      <Link to={`/d/${dashboard.id}`} className="text-purple-500">View</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          </div>
        </div>
      </div>
    );
}  

export default History;
