import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';

function Chat() {
  
    useEffect(() => {
      const fetchChat = async () => {
        // setIsLoading(true);  // Data fetching starts here. So, we set isLoading to true.
        // setIsLoading(false);  // Data fetching ends here. So, we set isLoading to false.
      }
      
      fetchChat();
    }, []);
  
    return (
      <div className="w-full flex-auto">
        <iframe 
          src="https://chat.text2bi.com/" 
          style={{width: '100%'}}
          title="Chat App"
        ></iframe>
      </div>
    );
}  

export default Chat;
