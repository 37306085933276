import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { inject } from '@vercel/analytics';

inject();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope="openid profile email"
      authorizationParams={{
        redirect_uri: `${window.location.origin}`
      }}
      cacheLocation="localstorage">
      <Router>
        <App />
      </Router>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
