import React, { useEffect } from "react";
import { useRoutes, Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoginPage from "./components/LoginPage";
import DashboardGenerator from "./components/DashboardGenerator";
import Header from "./components/Header";
import History from "./components/History";
import DataSources from "./components/DataSources";
import Loading from "./components/Loading";
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import TermsOfUsePage from './components/TermsOfUsePage';
import Admin from "./components/Admin";
import CodeCompressor from "./components/CodeCompressor";
import Dashboard from './components/Dashboard'; // Add the import at the top
import Chat from './components/Chat';
import { logEvent } from "firebase/analytics";
import { analytics } from './firebase';  // Adjust this path to match your project structure

function App() {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    // Perform a page_view event on every URL change
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_location: window.location.href,
      page_title: document.title,
    });
  }, [location]);

  const element = useRoutes([
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "/terms-of-use",
      element: <TermsOfUsePage />,
    },
    {
      path: "/",
      element: isAuthenticated ? (
        <div className='min-h-screen bg-fafafc pt-16 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8'>
          <Header />
          <DashboardGenerator />
        </div>
      ) : (
        <Navigate to='/login' />
      ),
    },
    {
      path: "/history",
      element: isAuthenticated ? (
        <div className='min-h-screen bg-fafafc pt-16 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8'>
          <Header />
          <History />
        </div>
      ) : (
        <Navigate to='/login' />
      ),
    },
    {
      path: "/data-sources",
      element: isAuthenticated ? (
        <div className='min-h-screen bg-fafafc pt-16 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8'>
          <Header />
          <DataSources />
        </div>
      ) : (
        <Navigate to='/login' />
      ),
    },
    {
      path: "/admin",
      element: isAuthenticated ? (
        <div className='min-h-screen bg-fafafc pt-16 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8'>
          <Header />
          <Admin />
        </div>
      ) : (
        <Navigate to='/login' />
      ),
    },
    { path: "/d/:id", element: isAuthenticated ? (
      <div className='min-h-screen bg-fafafc pt-16 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8'>
        <Header />
        <Dashboard />
      </div>
    ) : (
      <Navigate to='/login' />
    ), 
    },
    {
      path: "/chat",
      element: isAuthenticated ? (
        <div className='min-h-screen bg-fafafc pt-16 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8'>
          <Header />
          <Chat />
        </div>
      ) : (
        <Navigate to='/login' />
      ),
    },
    {
      path: "/code",
      element: isAuthenticated ? (
        <div className='min-h-screen bg-fafafc pt-16 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8'>
          <Header />
          <CodeCompressor />
        </div>
      ) : (
        <Navigate to='/login' />
      ),
    },
  ]);

  if (isLoading) {
    return <Loading />;
  }

  return element;
}

export default App;
