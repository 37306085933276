import React, { useState, Fragment, useEffect } from 'react';
import axios from 'axios';
import ChartImage from './ChartImage';
import Scorecards from './Scorecards';  // Import the Scorecards component at the top
import DashboardTitle from './DashboardTitle'; // Import the DashboardTitle component
import ImageGrid from './ImageGrid';
import FeedbackForm from './FeedbackForm';
import { Tab } from '@headlessui/react'
import { MoonLoader } from 'react-spinners';
import { useAuth0 } from '@auth0/auth0-react';
import { Listbox, Transition } from '@headlessui/react'
import CheckIcon from '@heroicons/react/24/outline/CheckIcon'
import ChevronUpDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import { logEvent } from "firebase/analytics";
import { analytics } from '../firebase'
import { initializeApp } from "firebase/app";
import { collection, doc, getDocs, getFirestore, getDoc } from 'firebase/firestore';
import SummaryTable from './SummaryTable';

const dataSource = [
  { id: "Emulated Data", name: 'Emulated Data' },
  // { id: "Demo GA4", name: 'Demo GA4' },
  { id: "Demo Multi-Channel", name: 'Demo Multi-Channel' },
  { id: "FB Ads (Supermetrics)", name: 'FB Ads (Supermetrics)' },
  // { id: "BQ", name: 'BQ' },
];

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const layouts = [
  { name: '3', value: 'grid-cols-3' },
  { name: '2', value: 'grid-cols-2' },
  { name: '⚙️', value: 'grid-cols-4' },
];

// const colorPalettes = [
//   { name: 'Microsoft', value: 'Use_Microsoft_Color_Pallet' },
//   { name: 'Tableau', value: 'Use_Tableau_Color_Pallet' },
//   { name: 'Excel', value: 'Use_Excel_Color_Pallet' },
//   { name: 'High Contrast', value: 'Use_High_Contrast_Color_Pallet' },
//   { name: 'Monochrome', value: 'Use_Monochrome_Color_Pallet' },
//   { name: 'Gothic', value: 'Use_Gothic_Color_Pallet' },
//   { name: 'Pastel', value: 'Use_Pastel_Color_Pallet' },
//   { name: 'Retro', value: 'Use_Retro_Color_Pallet' },
//   { name: 'Solarized', value: 'Use_Solarized_Color_Pallet' },
//   { name: 'Vibrant', value: 'Use_Vibrant_Color_Pallet' },
//   { name: 'Warm', value: 'Use_Warm_Color_Pallet' },
//   { name: 'Cool', value: 'Use_Cool_Color_Pallet' },
// ];

const exampleImages = [
  {src: 'https://drive.google.com/uc?id=1nLJ8kKzYR-EdQ4_qeWlsZFErVhRx8_yy', title: 'Create an insightful dashboard for my Google Analytics data.'},
  // {src: 'https://drive.google.com/uc?id=1KldyIz0cZP28O_cm1n7_rgJFqW7Wwie3', title: 'Build an insightful monthly expenses tracking dashboard based on the fact that I have a monthly expense budget of 250,000 and 1/3rd of it is for food. another 1/3rd is for educational stuff. rest is maintenance and loan repayments'},
  {src: 'https://drive.google.com/uc?id=11stGcKDdysunZJy-mC4rTBIJ9b0q2lZE', title: 'Marketing Campaign Dashboard: Monitor the effectiveness of marketing campaigns by tracking metrics such as click-through rates, conversion rates, cost per acquisition, and campaign ROl.'},
  {src: 'https://drive.google.com/uc?id=1nO7ijRmPOJIhnnddU3l68EW3LRtHUPRo', title: 'Create an interactive dashboard for my Twitter engagement metrics.'},
  {src: 'https://drive.google.com/uc?id=1iPfr9cTBCOxQ4_S2qCaGGG-7OQBpJHy_', title: 'Generate a comprehensive dashboard for my LinkedIn advertising campaign performance data.'},
  {src: 'https://drive.google.com/uc?id=1XXcm2j_676ggS6oToYvoo_m12s3ztOAx', title: 'HR Analytics Dashboard: Analyze HR data to measure employee performance, track attrition rates, monitor recruitment metrics, and identify training and development needs.'},
  {src: 'https://drive.google.com/uc?id=1U6F9Y_40ulNXmY3uBSaCWmZ4GdqQHAgR', title: 'Supply Chain Dashboard: Optimize supply chain management by analyzing inventory levels, order fulfillment metrics, supplier performance, and logistics efficiency.'},
  {src: 'https://drive.google.com/uc?id=1o8rhZRUHHro3oEEnYOUZDC8S8kt3WSMh', title: 'Sales Performance Dashboard: Analyze sales data to track revenue, identify top-performing products, monitor sales trends, and compare performance across regions or sales teams.'},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function DashboardGenerator() {
  const { user } = useAuth0();

  const surprisePrompts = [
    "Create an insightful dashboard for my Google Analytics data.",
    "Design a dashboard to visualize my website's user behavior data.",
    "Generate a comprehensive dashboard for my Facebook Ads performance data.",
    "Design a visually appealing dashboard for my Shopify sales and customer data.",
    "Create an interactive dashboard for my Twitter engagement metrics.",
    "Generate a detailed dashboard for my YouTube channel analytics.",
    "Design a real-time dashboard for my Amazon Web Services (AWS) usage data.",
    "Create an insightful dashboard for my Microsoft Excel sales and inventory data.",
    "Generate a comprehensive dashboard for my LinkedIn advertising campaign performance data.",
    "Design an interactive dashboard for my Adobe Analytics web traffic data.",
    "Create a visually appealing dashboard for my MongoDB database performance metrics."
  ];
  const [prompt, setPrompt] = useState('');
  const [charts, setCharts] = useState([]);
  const [scorecards, setScorecards] = useState([]); // Add a new state for scorecards
  const [loading, setLoading] = useState(false);
  const [dashboardTitle, setDashboardTitle] = useState(''); // Add a new state for dashboardTitle
  const [dashboardId, setDashboardId] = useState(''); // Add a new state for dashboardTitle
  // const [colorPalette, setColorPalette] = useState(colorPalettes[0].value);
  const [useAdvanced, setUseAdvanced] = useState(false);
  const [useSupermetrics, setUseSupermetrics] = useState(false);
  const [tblName, setTblName] = useState('');
  const [fields, setFields] = useState('');
  const [sheetUrl, setSheetUrl] = useState('');
  // const [selected, setSelected] = useState(dataSource[0])
  const [dynamicDataSources, setDynamicDataSources] = useState([]);
  const [combinedDataSources, setCombinedDataSources] = useState(dataSource); // Combined data sources
  const [selected, setSelected] = useState(combinedDataSources[0]);
  const userId = user.sub.split('|')[1];
  const [tableTitle, setTableTitle] = useState('');
  const [tableContent, setTableContent] = useState('');

  useEffect(() => {
    const fetchDataSources = async () => {
      try {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().Plan === 'Pro') {
          const dsRef = collection(userDocRef, 'datasources');
          const querySnapshot = await getDocs(dsRef);
          let fetchedDataSources = querySnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name
          }));
          setDynamicDataSources(fetchedDataSources);
        }
      } catch (error) {
        console.log("Error fetching data sources: ", error);
      }
    };

    fetchDataSources();
  }, [userId]);

  useEffect(() => {
    setCombinedDataSources([...dataSource, ...dynamicDataSources]);
  }, [dynamicDataSources]);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let response;
    const apiUrl = process.env.REACT_APP_API_URL;
    if(useSupermetrics) {
      response = await axios.post(`${apiUrl}/api/generate-sm-dashboard`, { prompt });
    } else {
      response = useAdvanced
        ? await axios.post(`${apiUrl}/api/generate-real-dashboard`, {
            prompt,
            tbl_name: tblName,
            fields: fields,
            sheet_url: sheetUrl
          })
        : await axios.post(`${apiUrl}/api/generate-dashboard`, { prompt, uid: user.sub.split('|')[1], ds: selected.id });
    }
  
    if (response.data && response.data.charts && response.data.chart_titles) {
      const initialCharts = response.data.charts.map((chart, index) => ({ 
        src: chart, 
        loaded: false, 
        title: response.data.chart_titles[index] 
      }));
      setScorecards(response.data.scorecards);
      setDashboardTitle(response.data.dashboardTitle); // Set dashboardTitle from the response
      setTableTitle(response.data.tableTitle);
      setTableContent(response.data.table);
      setDashboardId(response.data.dashboardId);
      setCharts(initialCharts);
    } else {
      console.error('Unexpected response data:', response.data);
    }
  
    setLoading(false);
    logEvent(analytics, 'generate_button_click', { prompt_length: prompt.length });
  };  

  const handleSurpriseMe = () => {
    const randomPrompt = surprisePrompts[Math.floor(Math.random() * surprisePrompts.length)];
    setSelected(dataSource[0]);
    setPrompt(randomPrompt);
    logEvent(analytics, 'surprise_me_button_click');
  }

  const handleImageLoad = (src) => {
    setCharts(charts => charts.map(chart => chart.src === src ? { ...chart, loaded: true } : chart));
  };

  // const handleColorPaletteChange = (newColorPalette) => {
  //   setColorPalette(newColorPalette);

  return (
    <>
      <div className="mt-8 space-y-2 w-full max-w-6xl">
        <div className="flex justify-between items-center mb-5">
          <div className="flex items-center">
            <p className="text-xs font-light text-gray-700">
              {charts.length > 0 ? 'Edit the description of the dashboard' : 'Start with a description of the dashboard'}
            </p>
            {charts.length === 0 && (
              <button onClick={handleSurpriseMe} className="text-xs font-semibold bg-gray-200 text-gray-800 rounded-md px-2 py-1 hover:bg-gray-300 ml-2">
                Surprise me
              </button>
            )}
            {charts.length > 0 && (
                <button onClick={handleSurpriseMe} className="text-xs font-semibold bg-gray-200 text-gray-800 rounded-md px-2 py-1 hover:bg-gray-300 ml-2">
                Surprise me
                </button>
            )}
          </div>
          <div className="flex top-16">
            <Listbox value={selected} onChange={(selected) => { setSelected(selected); setPrompt(''); }}>
              <div className="relative mt-1 w-56">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-xs">
                  <span className="block truncate">{selected.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-4 w-4 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {combinedDataSources.map((dataSource, dataSourceIdx) => (
                      <Listbox.Option
                        key={dataSourceIdx}
                        className={({ active }) =>
                          `relative text-xs cursor-default select-none py-2 pl-10 pr-4 ${
                            active ? 'bg-purple-100 text-purple-500' : 'text-gray-900'
                          }`
                        }
                        value={dataSource}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {dataSource.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-purple-600">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="flex items-center shadow-custom transition-shadow duration-200 rounded-md bg-white focus-within:shadow-customFocus">
          <input
            type="text"
            name="dashboard-prompt"
            id="dashboard-prompt"
            autoComplete="off"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Create an insightful dashboard for my Google Analytics data."
            className="p-3 border-none placeholder:text-gray-300 focus:ring-0 focus:border-0 flex-grow sm:text-md bg-white rounded-md"
          />
          {useAdvanced && (
            <div>
              <input
                type="text"
                placeholder="Table name"
                value={tblName}
                onChange={(e) => setTblName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Fields"
                value={fields}
                onChange={(e) => setFields(e.target.value)}
              />
              <input
                type="text"
                placeholder="Sheet URL"
                value={sheetUrl}
                onChange={(e) => setSheetUrl(e.target.value)}
              />
            </div>
          )}
          <button 
            type="submit" 
            disabled={prompt === '' || loading} 
            className={`inline-flex items-center px-4 py-3 border border-transparent text-md font-medium rounded-r-md ${prompt === '' ? 'text-gray-400 bg-white' : 'text-white bg-black hover:bg-gray-900'}`}>
            {loading ? 
              <MoonLoader size={15} color={"#ffffff"} /> : 
              'Generate'
            }
          </button>
        </form>
        {!charts.length && (
          <div className="">
            <ImageGrid images={exampleImages} onExampleSelect={(prompt) => {
              setPrompt(prompt);
              setSelected(dataSource[0]);
            }} />
          </div>
        )}
      </div>
      {charts.length > 0 && (
        <div className="mt-5 w-full max-w-6xl">
          {/* <div className="w-36 ml-auto">
            <Listbox value={colorPalette} onChange={handleColorPaletteChange}>
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                {colorPalette.name}
              </Listbox.Button>
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {colorPalettes.map((palette) => (
                  <Listbox.Option key={palette.value} value={palette} className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                  }`
                  }>
                    {palette.name}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          </div> */}
          <Tab.Group>
          <div className="w-36 ml-auto">
              <Tab.List className="flex justify-center space-x-1 rounded-lg bg-gray-200/50 p-1">
                {layouts.map((layoutItem) => (
                  <Tab
                  key={layoutItem.value}
                  className={({ selected }) =>
                  classNames(
                      'w-full py-2 text-xs font-medium text-gray-500 focus:outline-none rounded-lg',
                      selected
                      ? 'bg-white shadow text-blue-600'
                      : 'hover:bg-white hover:text-gray-900'
                      )
                    }
                    >
                    {layoutItem.name}
                  </Tab>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels className="mt-5 mb-10">
              {layouts.map((layoutItem, idx) => (
                <Tab.Panel
                  key={idx}
                >
                {dashboardTitle && (
                    <DashboardTitle title={dashboardTitle} />
                )}
                {scorecards.length > 0 && (
                    <Scorecards scorecards={scorecards} />
                )}
                  <div className={`grid ${layoutItem.value} gap-4 w-full`}>
                  {charts
                    .slice()
                    .sort((a, b) => a.loaded === b.loaded ? 0 : a.loaded ? -1 : 1)
                    .map((chart, index) => (
                      <ChartImage key={index} src={chart.src} onLoad={handleImageLoad} loaded={chart.loaded} title={chart.title} />
                    ))}
                  </div>
                  {tableContent && tableTitle && (
                    <SummaryTable title={tableTitle} content={tableContent} />
                  )}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      )}
      {dashboardId && (
        <FeedbackForm uid={user.sub.split('|')[1]} did={dashboardId} />
      )}
    </>
  );
}

export default DashboardGenerator;