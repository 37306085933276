import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // adjust to your firebase config file path
import { useAuth0 } from '@auth0/auth0-react';
import DashboardGeneratorWithProps from './DashboardGeneratorWithProps';
import Loading from './Loading';

function Dashboard() {
  const { user } = useAuth0();
  const { id } = useParams();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const uid = user.sub.split('|')[1];

  useEffect(() => {
    const fetchDashboard = async () => {
      const dashboardRef = doc(db, "users", uid, "dashboards", id); // replace "uid" with the actual uid
      const dashboardSnap = await getDoc(dashboardRef);
      
      if (dashboardSnap.exists()) {
        const data = dashboardSnap.data();
        // Convert charts from Firestore format to the format used by DashboardGeneratorWithProps
        data.initialCharts = data.chart_titles.map((title, index) => ({
          src: data[`charts.${index}`],
          loaded: true,
          title
        }));
        setDashboardData(data);
        setLoading(false);
      } else {
        console.log("No such document!");
      }
    };

    fetchDashboard();
  }, [id]);

  if (loading) {
    return <Loading />; // you can replace this with a proper loading component
  }

  // pass the data to the DashboardGeneratorWithProps
  // adjust the props according to your DashboardGeneratorWithProps component's requirements
  return (
    <DashboardGeneratorWithProps
      initialPrompt={dashboardData.prompt}
      initialDashboardTitle={dashboardData.dashboardTitle}
      initialScorecards={dashboardData.scorecards}
      initialCharts={dashboardData.initialCharts}  // assuming charts are part of the fetched data
      initialTableTitle={dashboardData.tableTitle}
      initialTableContent={dashboardData.tableContent}
      // Add more initial props if necessary
    />
  );
}

export default Dashboard;
