import React from 'react';

const PrivacyPolicyPage = () => {
  const lastUpdatedDate = 'May 31, 2023';

  return (
    <div className="m-6">
      <h1 className="text-4xl font-bold mb-6">Privacy Policy</h1>
      <p className="text-lg text-gray-700 mb-8">
        At Text2BI, we are committed to protecting the privacy and security of our users. This Privacy Policy outlines how we collect, use, and disclose information when you use our website, Text2BI DASH·E.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>
      <p className="text-lg text-gray-700 mb-4">
        When you sign up for an account on Text2BI DASH·E, we collect your login email address to facilitate the authentication process and provide you with access to our services. We do not collect any personally identifiable information (PII) other than your login email address.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>
      <p className="text-lg text-gray-700 mb-4">
        We use your login email address solely for the purpose of providing you with access to Text2BI DASH·E and its features. We do not share, sell, or rent your information to any third parties.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">Web Analytics</h2>
      <p className="text-lg text-gray-700 mb-4">
        We may collect non-personally identifiable information about your use of Text2BI DASH·E through web analytics tools. This information includes your browser type, device information, and usage patterns. We use this information to analyze and improve the performance and functionality of our website.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">Data Security</h2>
      <p className="text-lg text-gray-700 mb-4">
        We take the security of your information seriously and employ industry-standard measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
      </p>
      
      <h2 className="text-2xl font-bold mb-4">Changes to this Privacy Policy</h2>
      <p className="text-lg text-gray-700 mb-4">
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date at the bottom of the page will be revised accordingly. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your information.
      </p>
      
      <p className="text-lg text-gray-700 mb-8">
        If you have any questions or concerns about our Privacy Policy, please contact us at founders [at] text2bi.com.
      </p>
      
      <p className="text-sm text-gray-500">Last Updated: {lastUpdatedDate}</p>
    </div>
  );
};

export default PrivacyPolicyPage;
