import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { v4 as uuidv4 } from 'uuid';
import { initializeApp } from "firebase/app";
import { collection, doc, getDocs, setDoc, getFirestore, getDoc, updateDoc } from 'firebase/firestore';
import { analytics } from '../firebase';
import { logEvent } from "firebase/analytics";
import BeatLoader from "react-spinners/BeatLoader";
import { useAuth0 } from '@auth0/auth0-react';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function DataSources() {

    const sampleDataSource = [
    //   {datasourceName: "Demo GA4", datasourceType: "Google Sheets", datasourceUrl: "https://docs.google.com/spreadsheets/d/1e1-K42tnD5LnBplX6aP5o2X1jbzrEVoq1LwjsN_UAHk/edit?usp=sharing"},
      {datasourceName: "Demo Multi-Channel", datasourceType: "Google Sheets", datasourceUrl: "https://docs.google.com/spreadsheets/d/1e1-K42tnD5LnBplX6aP5o2X1jbzrEVoq1LwjsN_UAHk/edit?usp=sharing"},
      {datasourceName: "FB Ads (Supermetrics)", datasourceType: "Supermetrics API", datasourceUrl: "https://hub.supermetrics.com/"}
    ]

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [plan, setPlan] = useState('');
    const [dataSourceType, setDataSourceType] = useState('BigQuery');
    const [datasourceName, setDatasourceName] = useState('');
    const [tableName, setTableName] = useState('');
    const [fields, setFields] = useState('');
    const [instructions, setInstructions] = useState('');
    const [dataSources, setDataSources] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingDataSource, setEditingDataSource] = useState(null);

    const { user } = useAuth0();
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const userId = user.sub.split('|')[1];
          const userDocRef = doc(db, 'users', userId);
          const userDocSnap = await getDoc(userDocRef);
          
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setPlan(userData.Plan);
            
            const dataSourcesCollectionRef = collection(userDocRef, 'datasources');
            const dataSourcesSnapshot = await getDocs(dataSourcesCollectionRef);
            
            // Include document id with data
            const dataSourcesData = dataSourcesSnapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));
            setDataSources(dataSourcesData);
          } else {
            // No such document
          }
        } catch (error) {
          // Error fetching data
        }
      };
    
      fetchData();
    }, []);

    function closeModal() {
      setIsModalOpen(false);
    }

    function openModal() {
      setIsModalOpen(true);
      logEvent(analytics, 'add_data_source_click');
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const userId = user.sub.split('|')[1];
    
      try {
        const dataSourceDoc = doc(collection(doc(db, 'users', userId), 'datasources'), uuidv4());
        await setDoc(dataSourceDoc, {
          name: datasourceName,
          type: dataSourceType,
          tblName: tableName,
          fields: fields.split(',').map(field => field.trim()),
          instructions: instructions,
        });
    
        const dataSourcesSnap = await getDocs(collection(doc(db, 'users', userId), 'datasources'));
        // Include document id with data
        setDataSources(dataSourcesSnap.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() })));
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    
      setLoading(false);
      closeModal();
    }    
    
    function openEditModal(dataSource) {
      setEditingDataSource(dataSource);
      setDatasourceName(dataSource.name);
      setDataSourceType(dataSource.type);
      setTableName(dataSource.tblName);
      setFields(dataSource.fields);
      setInstructions(dataSource.instructions);
      setIsEditModalOpen(true);
    }

    function closeEditModal() {
      setEditingDataSource(null);
      setIsEditModalOpen(false);
    }

    const handleEdit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const userId = user.sub.split('|')[1];
      try {
        const dataSourceDoc = doc(db, 'users', userId, 'datasources', editingDataSource.id);
        await updateDoc(dataSourceDoc, {
          name: datasourceName,
          type: dataSourceType,
          tblName: tableName,
          fields: fields,
          instructions: instructions,
        });
    
        setDataSources(dataSources.map(ds => ds.id === editingDataSource.id ? { ...ds, name: datasourceName, type: dataSourceType, tblName: tableName, fields: fields, instructions: instructions } : ds));
    
      } catch (error) {
        console.error("Error updating document: ", error);
      }
      setLoading(false);
      closeEditModal();
    }

    return (
      <div className="w-full flex-auto">
        <div className="px-16 pt-5 ml-16 mb-4">
          <div className="flex justify-between items-center">
            <h1 className="mt-12 mb-6 text-xl font-bold text-gray-900">Data Sources</h1>
            <button onClick={openModal} className="bg-purple-600 text-white py-2 px-4 rounded-lg text-sm">Add data source</button>
          </div>
          <div className="flex space-x-4 mt-8">
          </div>
          <div className="mt-8">
            <table className="table-auto border-collapse w-full">
              <thead>
                <tr className="rounded-lg text-sm font-medium text-gray-700 text-left" style={{fontSize: '0.9674rem'}}>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Source</th>
                  <th className="px-4 py-2">URL</th>
                  <th className="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="text-sm font-normal text-gray-700">
                {[...dataSources, ...sampleDataSource].map((dataSource, index) => (
                  <tr key={index} className="hover:bg-gray-100 border-b border-gray-200 py-10">
                    <td className="px-4 py-4">
                      <a href={dataSource.datasourceUrl} target="_blank" rel="noopener noreferrer">{dataSource.datasourceName || dataSource.name}</a>
                    </td>
                    <td className="px-4 py-4">{dataSource.datasourceType || dataSource.type}</td>
                    <td className="px-4 py-4">
                      <a href={dataSource.datasourceUrl} target="_blank" rel="noopener noreferrer">{dataSource.datasourceUrl}</a>
                    </td>
                    <td className="px-4 py-4">
                    {dataSource.datasourceUrl ? (
                      <a href={dataSource.datasourceUrl} target="_blank" rel="noopener noreferrer" className="text-purple-600">View</a>
                    ) : (
                      <a onClick={() => openEditModal(dataSource)} className="text-purple-600 cursor-pointer">Edit</a>
                    )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Transition appear show={isEditModalOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeEditModal} >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

              {plan === 'Pro' ? (
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                  <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Edit Data Source
                    </Dialog.Title>
                    <form onSubmit={handleEdit}>
                      <div className="mt-4">
                        <label htmlFor="tableName" className="block text-sm font-medium text-gray-700">
                          Name <span className="text-purple-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="tableName"
                          id="tableName"
                          defaultValue={editingDataSource?.name}
                          onChange={e => setDatasourceName(e.target.value)}
                          required
                          className="mt-1 block w-full border-gray-300 sm:text-sm rounded-md"
                        />
                      </div>

                      <div className="mt-4">
                        <label htmlFor="datasourceType" className="block text-sm font-medium text-gray-700">
                          Type <span className="text-purple-600">*</span>
                        </label>
                        <select
                          id="datasourceType"
                          name="datasourceType"
                          defaultValue={editingDataSource?.type}
                          onChange={e => setDataSourceType(e.target.value)}
                          required
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                          <option>BigQuery</option>
                          {/* Add other data source types as options */}
                        </select>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="tblName" className="block text-sm font-medium text-gray-700">
                          Table Name <span className="text-purple-600">*</span>
                        </label>
                        <input
                          type="text"
                          name="tblName"
                          id="tblName"
                          defaultValue={editingDataSource?.tblName}
                          onChange={e => setTableName(e.target.value)}
                          required
                          className="mt-1 block w-full border-gray-300 sm:text-sm rounded-md"
                        />
                      </div>

                      <div className="mt-4">
                        <label htmlFor="fields" className="block text-sm font-medium text-gray-700">
                          Fields <span className="text-purple-600">*</span>
                        </label>
                        <textarea
                          id="fields"
                          name="fields"
                          rows="6"
                          defaultValue={editingDataSource?.fields}
                          onChange={e => setFields(e.target.value)}
                          required
                          className="mt-1 block w-full border-gray-300 sm:text-sm rounded-md"
                        ></textarea>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="instructions" className="block text-sm font-medium text-gray-700">
                          Instructions
                        </label>
                        <textarea
                          id="instructions"
                          name="instructions"
                          rows="2"
                          defaultValue={editingDataSource?.instructions}
                          onChange={e => setInstructions(e.target.value)}
                          className="mt-1 block w-full border-gray-300 sm:text-sm rounded-md"
                        ></textarea>
                      </div>

                      <div className="mt-4 flex justify-end space-x-4">
                        <button type="button" onClick={closeEditModal} className="inline-flex justify-center px-4 py-2 text-sm font-medium text-purple-600 border border-purple-600 rounded-md hover:bg-purple-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500">
                          Cancel
                        </button>

                        <button type="submit" className="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
                          {loading ? (
                            <div className="flex items-center justify-center">
                              <BeatLoader size={8} color="#ffffff" />
                            </div>
                          ) : (
                            'Save'
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </Transition.Child>
              ) : (
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                      >
                          Edit Data Source
                      </Dialog.Title>
                      <div className="mt-2">
                          <p className="text-sm text-gray-500">
                          Adding/Editting custom data sources is a pro feature and requires manual onboarding by us. You can get in touch with us to enable this feature.
                          </p>
                      </div>

                      <div className="mt-4 flex justify-end space-x-4">
                          <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-purple-600 border border-purple-600 rounded-md hover:bg-purple-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
                          onClick={closeModal}
                          >
                          Cancel
                          </button>
                          <a
                          href="mailto:founders@text2bi.com?subject=Request to Upgrade to Pro Plan for Custom Data Sources"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                          >
                          Contact us
                          </a>
                      </div>
                  </div>
                </Transition.Child>
              )}
            </div>
          </Dialog>
        </Transition>

        <Transition appear show={isModalOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

              {plan === 'Pro' ? (
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95" >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900" > Add Data Source </Dialog.Title>
                        <form onSubmit={handleSubmit}>
                            <div className="mt-4">
                                <label htmlFor="tableName" className="block text-sm font-medium text-gray-700">
                                    Name <span className="text-purple-600">*</span>
                                </label>
                                <input type="text" name="tableName" id="tableName" onChange={e => setDatasourceName(e.target.value)} required className="mt-1 block w-full border-gray-300 sm:text-sm rounded-md"/>
                            </div>
                            <div className="mt-4">
                                <label htmlFor="datasourceType" className="block text-sm font-medium text-gray-700">Type <span className="text-purple-600">*</span></label>
                                <select id="datasourceType" name="datasourceType" onChange={e => setDataSourceType(e.target.value)} required className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                                    <option>BigQuery</option>
                                    {/* <option>Google Sheets</option> */}
                                </select>
                            </div>
                            <div className="mt-4">
                                <label htmlFor="tableName" className="block text-sm font-medium text-gray-700">
                                    Table Name <span className="text-purple-600">*</span>
                                </label>
                                <input type="text" name="tableName" id="tableName" onChange={e => setTableName(e.target.value)} required className="mt-1 block w-full border-gray-300 sm:text-sm rounded-md"/>
                            </div>
                            <div className="mt-4">
                                <label htmlFor="fields" className="block text-sm font-medium text-gray-700">
                                    Fields <span className="text-purple-600">*</span>
                                </label>
                                <textarea id="fields" name="fields" rows="6" onChange={e => setFields(e.target.value)} required className="mt-1 block w-full border-gray-300 sm:text-sm rounded-md"></textarea>
                            </div>
                            <div className="mt-4">
                              <label htmlFor="instructions" className="block text-sm font-medium text-gray-700">Instructions</label>
                              <textarea id="instructions" name="instructions" rows="2" onChange={e => setInstructions(e.target.value)} className="mt-1 block w-full border-gray-300 sm:text-sm rounded-md"></textarea>
                            </div>
                            <div className="mt-4 flex justify-end space-x-4">
                                <button type="button" className="inline-flex justify-center px-4 py-2 text-sm font-medium text-purple-600 border border-purple-600 rounded-md hover:bg-purple-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500" onClick={closeModal} > Cancel </button>
                                <button type="submit" className="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" >
                                {loading ? (
                                  <div className="flex items-center justify-center">
                                    <BeatLoader size={8} color="#ffffff" />
                                  </div>
                                ) : (
                                  'Add'
                                )}
                                </button>
                            </div>
                        </form>
                    </div>
                </Transition.Child>            
              ) : (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                    >
                        Add Data Source
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                        Adding custom data sources is a pro feature and requires manual onboarding by us. You can get in touch with us to enable this feature.
                        </p>
                    </div>

                    <div className="mt-4 flex justify-end space-x-4">
                        <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-purple-600 border border-purple-600 rounded-md hover:bg-purple-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-500"
                        onClick={closeModal}
                        >
                        Cancel
                        </button>
                        <a
                        href="mailto:founders@text2bi.com?subject=Request to Upgrade to Pro Plan for Custom Data Sources"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        >
                        Contact us
                        </a>
                    </div>
                </div>
              </Transition.Child>
              )}
            </div>
          </Dialog>
        </Transition>
      </div>
    );
}  

export default DataSources;
