import React, { useState } from 'react';

const CodeCompressor = () => {
  const [originalCode, setOriginalCode] = useState('');
  const [compressedCode, setCompressedCode] = useState('');

  const compressCode = () => {
    // Remove commented code
    let codeWithoutComments = originalCode.replace(/\/\/.*|\/\*[\s\S]*?\*\//g, '');
  
    // Minify variable names and remove unnecessary whitespace
    const compressed = codeWithoutComments
      .replace(/\s+/g, ' ');
  
    // Remove consecutive new lines
    const compressedWithNewLines = compressed.replace(/\n{2,}/g, '\n');
  
    setCompressedCode(compressedWithNewLines);
  };

  const decompressCode = () => {
    // Decompression logic goes here
  };

  const handleCodeChange = (event) => {
    setOriginalCode(event.target.value);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(compressedCode);
  };

  return (
    <div className="flex">
      <div className="w-1/2 p-4">
        <h2 className="text-lg font-bold mb-2">Original Code</h2>
        <textarea
          className="w-full h-64 p-2 border border-gray-300 rounded"
          value={originalCode}
          onChange={handleCodeChange}
        />
      </div>
      <div className="w-1/2 p-4">
        <h2 className="text-lg font-bold mb-2">Compressed Code</h2>
        <div className="flex">
          <textarea
            className="w-full h-64 p-2 border border-gray-300 rounded"
            value={compressedCode}
            readOnly
          />
          <button
            className="ml-2 px-4 py-2 bg-blue-500 text-white rounded"
            onClick={handleCopyClick}
            disabled={!compressedCode}
          >
            Copy
          </button>
        </div>
      </div>
      <div className="w-full p-4">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded"
          onClick={compressCode}
        >
          Compress
        </button>
        <button
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded"
          onClick={decompressCode}
        >
          Decompress
        </button>
      </div>
    </div>
  );
};

export default CodeCompressor;
