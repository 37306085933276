import React, { useState, useEffect, useRef } from "react";
import { FiEdit, FiCheck, FiX } from 'react-icons/fi';

function ChartImage({ src, onLoad, loaded, title }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editTitle, setEditTitle] = useState('');
  const [displayedTitle, setDisplayedTitle] = useState('');
  const [imgSrc, setImgSrc] = useState(src);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setDisplayedTitle(title);
    setImgSrc(src);
    setIsImgLoaded(loaded);
  }, [title, src, loaded]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleImageLoad = () => {
    setIsImgLoaded(true);
    onLoad(src);
  };

  const formatTitle = (title) => {
    return title.replace(/ - (Bar|Line|Pie|Donut) Chart|\((Bar|Line|Pie|Donut)\)/gi, '');
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setEditTitle(formatTitle(displayedTitle));
  };

  const handleCheckClick = () => {
    if (editTitle !== formatTitle(displayedTitle)) {
      updateTitle(editTitle);  
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const updateTitle = (newTitle) => {
    const titlePart = formatTitle(displayedTitle).replace(/ /g, "_");
    const newTitlePart = newTitle.replace(/ /g, "_");

    // Remove everything after '&ct='
    let baseSrc = imgSrc.split("&ct=")[0];
    // Replace the old title with the new one
    let newSrc = baseSrc.replace(titlePart, newTitlePart);

    setImgSrc(newSrc + "&ct=" + newTitlePart);
    setDisplayedTitle(newTitle);
    setIsImgLoaded(false);
  };

  return (
    <div className="relative p-4 bg-white rounded-md shadow-lg">
      {!isImgLoaded && <div className="animate-pulse bg-gray-300 rounded-lg h-32" />}
      {isImgLoaded && 
        <div className="flex justify-between items-center mb-2 leading-snug">
          {isEditing 
            ? <input ref={inputRef} type="text" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} className="flex-auto mr-2 pt-0 pl-0 pb-0.5 text-s font-semibold text-gray-700 border-b border-t-0 border-l-0 border-r-0 border-grey-dark focus:outline-none" />
            : <h3 className="pb-1 text-s font-semibold text-gray-700">{formatTitle(displayedTitle)}</h3>}
          <div className="text-gray-700 flex">
            {isEditing
              ? <>
                  <FiCheck onClick={handleCheckClick} className="cursor-pointer text-gray-500 hover:text-gray-700 mr-2" />
                  <FiX onClick={handleCancelClick} className="cursor-pointer text-gray-500 hover:text-gray-700" />
                </>
              : !imgSrc.includes("https://quickchart.io/chart?c=") && <FiEdit onClick={handleEditClick} className="cursor-pointer text-gray-500 hover:text-gray-700" />}
          </div>
        </div>
      }
      <img src={imgSrc} onLoad={handleImageLoad} style={{ display: isImgLoaded ? 'block' : 'none' }} alt="Chart" className="w-full object-contain rounded-md" />
    </div>
  );
}      

export default ChartImage;
