import React, { Fragment, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Popover, Transition, Switch } from '@headlessui/react';
import { useLocation } from 'react-router-dom';

function Header() {
  const { user, isAuthenticated, loginWithRedirect, logout } =  useAuth0();
  const location = useLocation();
  const [experimentalFeaturesOn, setExperimentalFeaturesOn] = useState(false);
  const adminIds = process.env.REACT_APP_ADMIN_IDS.split(",");

  const profilePicture = user?.picture || '';

  const linkClasses = "mr-6 font-bold text-gray-500 no-underline text-sm hover:text-gray-400";
  const activeLinkClasses = "mr-6 font-bold text-gray-900 no-underline text-sm hover:text-gray-800";

  return (
    <header className="z-20 fixed left-0 right-0 top-0 w-full bg-white h-16 px-5 flex items-center justify-start shadow-sm">
      <a href="/" ><img src={"https://uploads-ssl.webflow.com/63cccaedfbf4f5b77a1ed30e/6421ae91753164651c46d2cd_favicon1_256px.png"} alt="Autopilot" className="w-5 h-5 mr-4 rounded-sm" /></a>
      <a href="/" className={location.pathname === "/" ? activeLinkClasses : linkClasses}>DASH·E</a>
      <a href="/history" className={location.pathname === "/history" ? activeLinkClasses : linkClasses}>History</a>
      <a href="/data-sources" className={location.pathname === "/data-sources" ? activeLinkClasses : linkClasses}>Data Sources</a>
      <a href="/chat" className={location.pathname === "/chat" ? activeLinkClasses : linkClasses}>Chat</a>
      {isAuthenticated && adminIds.includes(user.sub) && (
        <a href="/admin" className={location.pathname === "/admin" ? activeLinkClasses : "mr-6 font-bold text-white no-underline text-sm hover:text-gray-400"}>Admin</a>
      )}
      {isAuthenticated ? (
        <div className="ml-auto">
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button>
                  <img src={profilePicture} alt="Profile" className="h-8 w-8 rounded-full" />
                </Popover.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel static className="absolute right-0 w-64 mt-2 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" style={{ width: '250px' }}>
                    <div className="px-4 py-4">
                      <p className="text-sm font-medium text-gray-900">{user.name}</p>
                      <p className="text-xs text-gray-500">{user.email}</p>
                    </div>
                    {/* <div className="px-4 py-2 flex items-center justify-between">
                      <span className="text-sm">Pro Features</span>
                      <Switch
                        checked={experimentalFeaturesOn}
                        onChange={setExperimentalFeaturesOn}
                        className={`${
                          experimentalFeaturesOn ? 'bg-purple-500' : 'bg-gray-200'
                        } relative inline-flex items-center h-4 rounded-full w-8 transition-colors focus:outline-none`}
                      >
                        <span className="sr-only">Pro Features</span>
                        <span
                          className={`${
                            experimentalFeaturesOn ? 'translate-x-4' : 'translate-x-1'
                          } inline-block w-3 h-3 transform bg-white rounded-full transition-transform`}
                        />
                      </Switch>
                    </div> */}
                    <div className="px-4 py-2">
                      <button onClick={() => logout({ returnTo: window.location.origin })} className="w-full text-sm text-left">Sign out</button>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      ) : (
        <button onClick={loginWithRedirect} className="ml-auto">
          Log in
        </button>
      )}
    </header>
  );
}  

export default Header;
